/* eslint-disable */
<template>
  <v-sheet
    class="chatbox"
    elevation="3"
    data-aos="fade-up"
    data-aos-delay="150"
  >
    <div class="wrapper scrollable-element">
      <single-chat-text
        v-for="(call, idx) in calls"
        :key="idx"
        :username="call.name"
        :text="call.value"
        :datetime="call.date"
      />
    </div>
  </v-sheet>
</template>

<script>
import SingleChatText from "@/components/SingleChatText";
export default {
  name: "ChatBox",
  components: { SingleChatText },
  props: ["calls"],
};
</script>

<style scoped lang="scss">
.chatbox {
  padding: 12px 6px;
  background: linear-gradient(135deg, #94d3ef, #679dff);
  border-radius: 4px;
  height: 400px;
  .wrapper {
    overflow-y: scroll;
    height: 100%;
    padding: 8px;
  }
}
</style>
