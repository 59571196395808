<template>
  <div
    class="h-100 d-flex justify-space-between flex-column"
    v-breathing-colors="gradientBackgroundColor"
  >
    <section class="d-flex align-center py-12">
      <v-row class="flex-column-reverse flex-md-row">
        <v-col cols="12" md="6" class="px-md-12">
          <v-sheet
            class="pa-md-6 pa-4 standard-gradient"
            rounded
            elevation="6"
            data-aos="fade-up"
            dark
          >
            <h4 class="text-center mb-6 text--gold text-md-h4 text-h6">
              Open a Free Demat Account Now!
            </h4>
            <v-text-field label="Name" prepend-icon="mdi-account" />
            <v-text-field label="Email" prepend-icon="mdi-email" />
            <v-text-field
              label="Mobile Number"
              prepend-icon="mdi-cellphone"
            ></v-text-field>
            <v-text-field
              label="Password"
              prepend-icon="mdi-lock"
              type="password"
            ></v-text-field>
            <v-text-field
              label="Referral Code / Employee Code"
              prepend-icon="mdi-cellphone"
            ></v-text-field>
            <div class="text-center">
              <ColorfulButton :animated="false" class="mt-4">
                Next Step<v-icon>mdi-chevron-right</v-icon>
              </ColorfulButton>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12 " class="h-100 px-md-8 mb-12 mb-md-0" md="6">
          <h2
            class="text-md-h2 text-h3 font-weight-black text-center mb-4"
            data-aos="fade-left"
          >
            Contact Us
          </h2>
          <p class="text-body-1 text-center" data-aos="fade-left">
            Whether you're looking to get your wealth generation journey
            started, or have any queries, feel free to contact us!
          </p>
          <v-list class="colorless mt-4" data-aos="fade-left">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-body-2 text-md-body-1">
                Goodwill Wealth Management Pvt Ltd.<br />
                New No #9(Old No 4/1) 2nd Floor,<br />
                Masha Allah Building,<br />
                Bheema Sena Garden Street,<br />
                Off:Royapettah High Road, <br />
                (Near Thiruvalluvar Statue), <br />Mylapore Chennai,TamilNadu -
                600 004
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cellphone</v-icon>
              </v-list-item-icon>
              <v-list-item-title> +91 - 44 - 4032 9999 </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <a href="mailto:info@gwcindia.in">info@gwcindia.in</a>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider class="my-2" />
          <h5 class="font-weight-bold mb-4" data-aos="fade-left">
            Social Media
          </h5>
          <v-row class="pl-4">
            <v-btn
              link
              class="cool-rounded-button mr-4"
              v-for="(media, idx) in socialMedia"
              :key="media.icon"
              :href="media.link"
              target="_blank"
              icon
              x-large
              data-aos="fade-up"
              :data-aos-delay="idx * 100"
            >
              <v-icon size="36">{{ media.icon }}</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <app-footer />
  </div>
</template>

<script>
import ColorfulButton from "@/components/ColorfulButton";
import AppFooter from "@/components/AppFooter";
export default {
  name: "ContactUs.vue",
  components: { AppFooter, ColorfulButton },
  data() {
    return {
      socialMedia: [
        { link: "https://www.facebook.com/gwcindia", icon: "mdi-facebook" },
        { link: "https://twitter.com/Goodwill_gwc_", icon: "mdi-twitter" },
        {
          link: "https://www.youtube.com/goodwillwealthmanagementpvtltd",
          icon: "mdi-youtube",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
