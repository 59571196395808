<!--TODO: add gwc icon & set app name properly -->
<!-- Please refer to Vue's official documentation & use Vue devtool to finish the tasks -->
<template>
  <v-app>
    <v-main class="overflow-hidden">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "App",

  data: () => ({
    //
  }),
  mounted() {
    AOS.init();
  },
};
</script>
