<template>
  <div class="justify-center align-center d-flex relative">
    <div class="line"></div>
    <v-icon class="diamond">mdi-rhombus</v-icon>
    <v-icon class="diamond pulsing">mdi-rhombus</v-icon>
  </div>
</template>

<script>
export default {
  name: "DiamondDivider",
};
</script>

<style scoped lang="scss">
.line {
  position: absolute;
  height: 2px;
  top: 50%;
  left: 0;
  width: 100%;
  background-color: lightgray;
  transform: translateY(-50%);
}
.diamond {
  background: linear-gradient(#021e82, #5937b9);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: visible;
  &.pulsing {
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center center;
    animation: my-pulse 1400ms ease-in infinite;
  }
}
@keyframes my-pulse {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  90% {
    opacity: 0.5;
    transform: scale(2);
  }
  100% {
    opacity: 0.5;
    transform: scale(2);
  }
}
</style>
