<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card dark>
      <v-card-title class="justify-center amber darken-2"
        >We will call you back</v-card-title
      >
      <v-card-text class="pt-2">
        <v-text-field label="Name" prepend-icon="mdi-account" v-model="name" />
        <v-text-field label="Email" prepend-icon="mdi-email" v-model="email" />
        <v-text-field
          label="Mobile Number"
          prepend-icon="mdi-cellphone"
          v-model="phone"
        />
        <v-checkbox
          label="I authorize Goodwill Wealth Management to Contact Me"
          v-model="contact"
        ></v-checkbox>
        <div class="text-center">
          <ColorfulButton :animated="false" @click="register"
            >Register</ColorfulButton
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ColorfulButton from "@/components/ColorfulButton";
import axios from "axios";
export default {
  name: "ContactUsDialog",
  components: { ColorfulButton },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      contact: false,
    };
  },
  props: ["value"],
  computed: {
    dialog: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    register() {
      // TODO: fill in the url here and the next action
      axios
        .post("https://app.gwcindia.live/API2022/api.php", {
          cmd: "registercall",
          name: this.name,
          email: this.email,
          phone: this.phone,
          contact: this.contact,
        })
        .then((res) => {
          console.log(res);
          this.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped></style>
