<template>
  <div
    class="performance-analysis"
    v-breathing-colors="gradientBackgroundColor"
  >
    <AppHeader>
      <template v-slot:top> Performance Analysis </template>
    </AppHeader>
    <main>
      <div class="px-12">
        <v-card class="px-4 py-4 gradient-background" elevation="3">
          <v-row>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-col
                    cols="12"
                    md="3"
                    class="pointer ma-4 j-shrine"
                    :class="hover ? 'elevation-6' : 'elevation-3'"
                    v-bind="attrs"
                    v-on="on"
                    @click="dateDialog = true"
                  >
                    <v-row>
                      <v-col cols="6">
                        <p class="text-center">
                          <v-icon size="64">mdi-calendar</v-icon>
                        </p>
                        <h3>The Dates</h3>
                      </v-col>
                      <v-col cols="6">
                        <p>
                          Start Date:
                          <br />
                          {{ toDateString(date[0]) }}
                        </p>
                        <p>
                          End Date:
                          <br />
                          {{ toDateString(date[1]) }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-hover>
              </template>
              <span>Click to set the ranges of date</span>
            </v-tooltip>
            <v-col cols="12" md="5" class="d-flex align-center justify-center">
              <h2>{{ strategy }}</h2>
            </v-col>
            <v-col
              class="right-col flex-grow-1 d-flex justify-center align-center"
            >
              <v-sheet
                class="j-shrine rounded-pill flex-grow-1 px-12 py-3 elevation-3"
              >
                <v-row align="center">
                  <v-col cols="6">
                    <h4 :class="{ profit: ploss > 0, loss: ploss < 0 }">
                      {{ ploss > 0 ? "PROFIT" : "LOSS" }}
                    </h4>
                  </v-col>
                  <v-col cols="6"> {{ ploss }} </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="6">
                    <h4>CALLS</h4>
                  </v-col>
                  <v-col cols="6"> {{ calls }} </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div class="px-12 my-8">
        <v-card class="elevation-1 data-table">
          <v-data-table
            :headers="headers"
            :items="items"
            fixed-header
            height="100%"
            style="height: calc(100% - 70px)"
            class="scrollable-element"
          ></v-data-table>
        </v-card>
      </div>
    </main>
    <DatePicker
      v-model="date"
      :dialog="dateDialog"
      @close="dateDialog = false"
      @completed="completed"
    />
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader";
import DatePicker from "@/components/DatePicker";
import axios from "axios";
import Vue from "vue";
export default {
  name: "PerformanceAnalysis",
  components: { AppHeader, DatePicker },
  data() {
    return {
      headers: [],
      items: [],
      strategy: this.$route.query.strategy,
      date: ["", ""],
      dateDialog: false,
      calls: 0,
      ploss: 0,
    };
  },
  methods: {
    completed() {
      this.date.sort((a, b) => {
        return this.parseDate(a) > this.parseDate(b) ? 1 : 0;
      });
      Vue.set(this.date, 0, this.date[0]);
      this.getData();
    },
    getData() {
      this.items = [];
      this.headers = [];
      this.calls = 0;
      this.ploss = 0;
      axios
        .get(
          "https://trade.gwcindia.live/report/json.php?Q=L&Strategy=EQ%20ATL%20BO&from=2020-01-01&to=2022-07-06",
          {
            params: {
              Strategy: this.strategy,
              from: this.date[0],
              to: this.date[1],
            },
          }
        )
        .then((res) => {
          if (res.data.Data.Filtered == []) {
            this.items = [];
            return;
          }
          this.calls = res.data.Data.Full[0].calls;
          this.ploss = res.data.Data.Full[0].ploss;

          for (let key in res.data.Data.Filtered[0]) {
            if (this.isNumeric(res.data.Data.Filtered[0][key])) {
              if (
                res.data.Data.Filtered.reduce((total, currentValue) => {
                  return total + parseFloat(currentValue[key]);
                }, 0) !== 0
              ) {
                this.headers.push({
                  text: key,
                  value: key,
                });
              }
            } else {
              this.headers.push({
                text: key,
                value: key,
              });
            }
          }
          for (let row of res.data.Data.Filtered) {
            this.items.push(row);
          }
        });
    },
  },
  created() {
    this.date = [this.$route.query.startDate, this.$route.query.endDate];
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.j-shrine {
  background: linear-gradient(90deg, #f64f59, #c471ed, #12c2e9);
  background-size: 240% 240%;
  animation: gradient-animation 3s ease infinite;
  transition: box-shadow 150ms linear;
}
.profit {
  color: lightgreen;
}
.loss {
  color: tomato;
}
.performance-analysis {
  height: 100%;
  display: flex;
  flex-direction: column;

  > .row {
    flex: none;
  }

  .or-label {
    text-align: center;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      height: 2px;
      width: 40%;
      content: "";
      background-color: black;
    }

    &::before {
      left: 50%;
      transform: translateY(-1px) translateX(15px);
    }

    &::after {
      right: 50%;
      transform: translateY(-1px) translateX(-15px);
    }
  }

  .circle {
    background-color: white;
    padding: 20px;
    border-radius: 50%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .data-table {
    animation: fade-up forwards 400ms linear;
    width: auto;
    background: linear-gradient(135deg, #94d3ef, #679dff);
    border: solid 3px gold;
  }

  .right-col {
    margin-top: 1rem;
    * {
      font-size: 1.4rem;
    }
  }
}

.gradient-border {
  --border-width: 6px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: calc(-1 * var(--border-width));
    top: calc(-1 * var(--border-width));
    background: linear-gradient(
      135deg,
      #3ce6e7,
      #b8ede4,
      #bdc5f5,
      #3ce6e7,
      #b8ede4,
      #bdc5f5,
      #3ce6e7
    );
    background-size: 400%;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    z-index: -1;
    animation: steam 4s linear infinite;
  }
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400% 0;
  }
}
</style>

<style>
.data-table .v-data-table {
  background-color: unset !important;
}
.data-table tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.3);
}
.data-table tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.1);
}
.data-table table tr > * {
  border-left: solid 1px gold;
}
.data-table table {
  border: solid 1px gold;
  border-top: none;
}

.data-table table tr > th {
  background-color: #9ccbf7 !important;
  font-weight: bold;
  font-size: 1rem !important;
  color: black !important;
  border-top: solid 1px gold;
}

.data-table
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: solid 1px gold !important;
}
</style>
