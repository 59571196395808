<template>
  <div v-breathing-colors="gradientBackgroundColor">
    <div class="relative mb-8" id="strategies">
      <AppHeader ref="header">
        <template v-slot:top-left>
          <v-btn
            color="#FFCC34"
            rounded
            class="animate__jello_delay animate__animated animate__infinite d-inline-block animate__slowest"
            @click="goTo('live-call')"
          >
            <h4>Live Calls</h4>
          </v-btn>
        </template>
        <template v-slot:top> {{ title }} </template>
        <template v-slot:add-on-top-right>
          <v-btn
            small
            style="
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translateY(calc(100% + 20px)) translateX(-50%);
              font-size: 12px;
              text-transform: unset;
            "
            color="#A59CD8"
            href="mailto:customersupport@gwcindia.in"
          >
            <v-icon size="16" class="mr-1">mdi-email</v-icon>
            customersupport@gwcindia.in
          </v-btn>
        </template>
      </AppHeader>
      <main>
        <v-row class="px-12" justify="center">
          <v-col
            v-for="(strategy, idx) in strategies"
            :key="idx"
            cols="3"
            class="d-flex flex-column"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                outlined
                :elevation="hover ? 6 : 2"
                class="shadow-transition gradient-background pointer flex-grow-1 d-flex flex-column"
                rounded
                @click="selectedStrategy = selectedStrategy === idx ? -1 : idx"
                :class="{
                  selected: selectedStrategy === idx,
                  dimmer: selectedStrategy > -1 && selectedStrategy !== idx,
                }"
              >
                <v-card-text class="d-flex flex-column flex-grow-1">
                  <h4 class="text--black text-center flex-grow-1">
                    {{ strategy.Category }}
                  </h4>
                  <v-divider class="my-3" />
                  <v-row>
                    <v-col class="text-center" cols="6">
                      <h5
                        class="pb-2"
                        :class="strategy.ploss > 0 ? 'profit' : 'loss'"
                      >
                        {{ strategy.ploss > 0 ? "PROFIT" : "LOSS" }}
                      </h5>
                      <h6>{{ strategy.ploss }}</h6>
                    </v-col>
                    <v-divider vertical />
                    <v-col class="text-center" cols="6">
                      <h5 class="pb-2">NO OF CALLS</h5>
                      <h6>{{ strategy.calls }}</h6>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <v-tooltip top :value="showTooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="#FFCC34"
              absolute
              class="bottom-right animate__animated animate__infinite animate__heartBeat"
              v-bind="attrs"
              v-on="on"
              @click="dateDialog = true"
              :class="{ hide: selectedStrategy === -1 }"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>
          <span>Set the range of dates</span>
        </v-tooltip>
        <DatePicker
          v-model="date"
          :dialog="dateDialog"
          @close="dateDialog = false"
          @completed="completed"
        />
      </main>
    </div>
    <LiveCall id="live-call" ref="liveCall" />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import DatePicker from "@/components/DatePicker";
import LiveCall from "@/components/LiveCall";
import axios from "axios";

export default {
  name: "HomeView",
  components: { LiveCall, AppHeader, DatePicker },
  data() {
    return {
      strategies: [
        { Category: "MCX ATL B0", ploss: 100000, calls: 100 },
        { Category: "Equity Cash", ploss: 100000, calls: 100 },
        { Category: "ATL F&G", ploss: 100000, calls: 100 },
        { Category: "EQ-ATL-B0", ploss: 100000, calls: 100 },
        { Category: "MCX ATL B0", ploss: 100000, calls: 100 },
        { Category: "MCX ATL B0", ploss: -100000, calls: 100 },
        { Category: "MCX ATL B0", ploss: 100000, calls: 100 },
        { Category: "Equity Cash", ploss: -100000, calls: 100 },
        { Category: "ATL F&G", ploss: 100000, calls: 100 },
        { Category: "EQ-ATL-B0", ploss: -100000, calls: 100 },
        { Category: "MCX ATL B0", ploss: 100000, calls: 100 },
        { Category: "MCX ATL B0", ploss: -100000, calls: 100 },
        { Category: "MCX ATL B0", ploss: 100000, calls: 100 },
        { Category: "MCX ATL B0", ploss: 100000, calls: 100 },
      ],
      date: [],
      dateDialog: false,
      selectedStrategy: -1,
      showTooltip: false,
      title: "Pick Your Plans",
    };
  },
  watch: {
    selectedStrategy() {
      if (this.selectedStrategy !== -1) {
        setTimeout(() => (this.showTooltip = true), 100);
      } else {
        this.showTooltip = false;
      }
    },
  },
  methods: {
    completed() {
      this.date.sort((a, b) => {
        return this.parseDate(a) > this.parseDate(b) ? 1 : 0;
      });
      this.$router.push({
        path: "/report",
        query: {
          strategy: this.strategies[this.selectedStrategy].Category,
          startDate: this.date[0],
          endDate: this.date[1],
        },
      });
    },
  },
  mounted() {
    axios.get("https://trade.gwcindia.live/report/json.php?Q=F").then((res) => {
      this.strategies = res.data.Data.Full;
    });
    window.onscroll = () => {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top >= this.$refs.liveCall.$el.offsetTop - 100) {
        // eslint-disable-next-line
        this.title = "Live Call";
      } else {
        this.title = "Pick Your Plans";
      }
    };
  },
};
</script>

<style scoped lang="scss">
.profit {
  color: mediumspringgreen;
}
.loss {
  color: red;
}
.bottom-right {
  bottom: 20px;
  right: 20px;
}
.selected {
  box-shadow: gold 0 0 10px 2px !important;
}
</style>
