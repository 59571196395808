<template>
  <div v-breathing-colors="gradientBackgroundColor">
    <AppHeader ref="header">
      <template v-slot:top-left>
        <a
          v-if="!isAPI"
          href="https://trade.gwcindia.live/External/gwc.php"
          target="_blank"
          class="v-btn v-btn--text theme--light v-size--default secondary--text"
          >GWC API Login</a
        >
        <a
          v-else-if="isAPI"
          href="https://trade.gwcindia.live/External/gwcexpire.php"
          target="_blank"
          class="v-btn v-btn--text theme--light v-size--default secondary--text"
          >GWC API Logout</a
        >

        <!-- <router-link to="gwcapi" target="_blank"> 
          <v-btn
            color="#FFDEC4"
            rounded
            class="d-inline-block animate__slowest mr-4"
          >
            API Login
          </v-btn>
        </router-link> -->

        <v-btn
          color="#FFCC34"
          rounded
          class="animate__jello_delay animate__animated animate__infinite d-inline-block animate__slowest"
          @click="dialog = true"
        >
          My Plans
        </v-btn>
      </template>
      <template v-slot:top> Select Plan </template>
      <template v-slot:top-right>
        <router-link to="/dashboard">
          <v-btn
            color="#FFCC34"
            rounded
            class="animate__jello_delay animate__animated animate__infinite d-inline-block animate__slowest"
          >
            Follow Trades
          </v-btn>
        </router-link>

        <!--TODO: Logout action, probably delete the gwc-login-token on localstorage and navigate to some pages-->
        <v-btn text color="secondary" @click="Editor">Reports</v-btn>
        <v-btn text color="secondary" @click="logout">Log out </v-btn>
      </template>
    </AppHeader>
    <main>
      <v-row class="px-12 py-6" justify="center">
        <v-col
          v-for="(strategy, idx) in strategies"
          :key="idx"
          cols="3"
          class="d-flex flex-column"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              outlined
              :elevation="hover ? 6 : 2"
              class="shadow-transition gradient-background pointer flex-grow-1 d-flex flex-column"
              rounded
            >
              <v-card-text class="d-flex flex-column flex-grow-1">
                <h4 class="text--black text-center flex-grow-1">
                  {{ strategy.Category }}
                </h4>
                <v-divider class="my-3" />
                <v-row class="px-6">
                  <v-col cols="8">
                    <v-text-field
                      v-model="strategy.quantity"
                      class="mt-0 pt-0 centered-input"
                      type="number"
                      outlined
                      hide-details
                      :disabled="idx in addedStrategy"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon
                          color="#EDBE30"
                          @click="
                            !(idx in addedStrategy) && strategy.quantity--
                          "
                          :class="{ hidden: strategy.quantity <= 0 }"
                        >
                          mdi-minus
                        </v-icon>
                      </template>
                      <template v-slot:append>
                        <v-icon
                          color="#EDBE30"
                          @click="
                            !(idx in addedStrategy) && strategy.quantity++
                          "
                        >
                          mdi-plus
                        </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center align-center">
                    <v-btn
                      color="#EDBE30"
                      @click="addStrategy(idx)"
                      :disabled="idx in addedStrategy || strategy.quantity < 1"
                    >
                      {{ idx in addedStrategy ? "ADDED" : "ADD" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </main>
    <v-snackbar v-model="snackbar">
      Plan Added
      <v-btn text color="#EDBE30" @click="dialog = true">Check them now</v-btn>
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="500"
      transition="dialog-bottom-transition"
    >
      <v-card v-breathing-colors="gradientBackgroundColor">
        <v-card-title
          class="text-h5 text--white standard-gradient justify-center"
        >
          My Plans
        </v-card-title>

        <v-card-text>
          <div v-if="isEmpty">
            <h3 class="text-center py-4">No Plan Selected</h3>
          </div>
          <v-list v-else>
            <v-list-item
              v-for="(strategy, key) in addedStrategy"
              :key="strategy.Category"
              class="transparent"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="strategy.Category"
                  class="font-weight-bold"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="align-self-center">
                <v-text-field
                  v-model="strategy.quantity"
                  class="mt-0 pt-0 centered-input"
                  type="number"
                  outlined
                  hide-details
                  style="width: 140px"
                >
                  <template v-slot:prepend-inner>
                    <v-icon
                      color="#EDBE30"
                      @click="strategy.quantity--"
                      :class="{ hidden: strategy.quantity <= 1 }"
                    >
                      mdi-minus
                    </v-icon>
                  </template>
                  <template v-slot:append>
                    <v-icon color="#EDBE30" @click="strategy.quantity++">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-text-field>
              </v-list-item-icon>
              <v-list-item-action>
                <v-btn icon color="error" @click="removeStrategy(key)">
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions v-if="!isEmpty">
          <v-row no-gutters>
            <v-col cols="6"
              ><v-btn text color="#EDBE30" @click="uploadSelected"
                >Upload Plans</v-btn
              ></v-col
            >
            <v-col cols="6" class="text-center">
              <!-- TODO: Fill in these actions -->
              <!-- <v-btn text color="#EDBE30" onclick="">Link API</v-btn> -->
              <v-btn text color="#EDBE30">Clear Lists</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import axios from "axios";
import Vue from "vue";

export default {
  name: "MainAppView",
  components: { AppHeader },
  data() {
    return {
      strategies: [
        { Category: "MCX ATL BO", ploss: 100000, calls: 100, quantity: 0 },
      ],
      addedStrategy: {},
      snackbar: false,
      dialog: false,
      isAPI: false,
      isL: false,
      isDone: false,
    };
  },
  computed: {
    isEmpty() {
      return Object.keys(this.addedStrategy).length === 0;
    },
  },
  methods: {
    addStrategy(idx) {
      try {
        Vue.set(this.addedStrategy, idx, this.strategies[idx]);
      } catch (e) {
        console.log(e);
      }
      //Vue.set(this.addedStrategy, idx, this.strategies[idx]);
      this.snackbar = true;
    },
    renStrategy(idx) {
      try {
        Vue.set(this.addedStrategy, idx, this.strategies[idx]);
      } catch (e) {
        console.log(e);
      }
    },
    removeStrategy(idx) {
      Vue.delete(this.addedStrategy, idx);
    },
    uploadSelected() {
      axios
        .post("https://app.gwcindia.live/API2022/Strategies.php", {
          token: localStorage.getItem("gwc-login-token"),
          sel: this.addedStrategy,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadStrategies() {
      if (this.isL) return;
      if (!localStorage.getItem("gwc-login-token")) {
        this.$router.replace("/");
      }
      if (localStorage.getItem("gwc-api-token")) {
        this.isAPI = true;
      }
      var token = localStorage.getItem("gwc-login-token");
      var config = {
        headers: { token: token },
      };
      console.log("TOKEN", token);
      //console.log(this.$el.baseURI);
      //console.log(this.$el.baseURI.endsWith("/app"));
      if (token == undefined || token == null) {
        this.isL=false;
        setInterval(this.loadStrategies, 1000);
        return;
      }
      if (this.$el.baseURI.endsWith("/app") == false) {
        this.isL=false;
        setInterval(this.loadStrategies, 1000);
        return;
      }
      this.isL=true;
      if (this.isDone) return;
      axios
        .get("https://app.gwcindia.live/API2022/Strategies.php", config)
        .then((res) => {
          if (res.data.data == undefined) {
            this.isL=false;
            setInterval(this.loadStrategies, 2000);
            return;
          }
          this.isL = true;
          console.log(res);
          console.log("Request-1");
          this.strategies = res.data.data.map((strategy) => {
            return {
              Category: strategy.n,
              quantity: strategy.q,
              ploss: 0,
              calls: 0,
            };
          });
          this.strategies.forEach((x, y) => {
            for (var i = 0; i < x.quantity; i++) {
              this.renStrategy(y);
            }
          });
          this.isDone = true;
        });
    },
    logout() {
      axios
        .post("/External/logout.php", {
          cmd: "logout",
          token: localStorage.getItem("gwc-login-token"),
        })
        .then(() => {
          localStorage.clear();
          //window.location
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Editor() {
      window.open("https://app.gwcindia.live/", "_blank");
      //window.location.href = "https://trade.gwcindia.live/Trade/rd.php?token=" + localStorage.getItem("gwc-login-token");
      //this.$router.push("/gwctrade");
    },
  },
  mounted() {
    this.loadStrategies();
  },
};
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.hidden {
  visibility: hidden;
}
.v-list.v-sheet.theme--light {
  background-color: transparent !important;
}
</style>
