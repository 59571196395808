<template>
  <footer
    class="d-flex flex-column flex-md-row justify-center standard-gradient text--white py-5 pl-4 pl-md-0"
  >
    <span>© 2022 Goodwill. All Right Reserved</span>
    <a
      v-for="footer in footers"
      :key="footer.text"
      :href="footer.link"
      target="_blank"
      class="nice-link"
    >
      <span class="mx-3" v-if="!isMobile">|</span>
      {{ footer.text }}
    </a>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      footers: [
        { text: "Disclaimer", link: "https://www.gwcindia.in/Disclaimer" },
        {
          text: "Privacy Policy",
          link: "https://www.gwcindia.in/Privacypolicy",
        },
        {
          text: "Terms & Condition",
          link: "https://www.gwcindia.in/Termsandcondition",
        },
        {
          text: "Terms & Conditions - Products & Services",
          link: "https://www.gwcindia.in/TermsAndConditions-productandservices.aspx",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style scoped></style>
