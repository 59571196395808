<template>
  <v-dialog
    ref="dialog"
    v-model="dialogOpen"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <v-date-picker v-model="date" scrollable range show-current>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="dialogOpen = false"> Cancel </v-btn>
      <v-btn
        text
        color="primary"
        @click="
          $refs.dialog.save(date);
          $emit('completed');
        "
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "DatePicker",
  props: ["dialog", "value"],
  computed: {
    dialogOpen: {
      set(val) {
        this.$emit("close", val);
      },
      get() {
        return this.dialog;
      },
    },
    date: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

<style scoped></style>
