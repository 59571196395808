/* eslint-disable */
<template>
  <svg
    :width="width"
    viewBox="0 0 299.7 300.71"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="linearGradient1233"
        x1="161.92"
        x2="161.51"
        y1="1.2392"
        y2="296.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fa94ff" offset="0" />
        <stop stop-color="#1e23bb" offset="1" />
      </linearGradient>
      <filter
        id="filter2662"
        x="-.099927"
        y="-.11402"
        width="1.1999"
        height="1.228"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-color="rgb(24,144,33)"
          flood-opacity=".49804"
          result="flood"
        />
        <feComposite
          in="flood"
          in2="SourceGraphic"
          operator="in"
          result="composite1"
        />
        <feGaussianBlur in="composite1" result="blur" stdDeviation="10" />
        <feOffset dx="0" dy="0" result="offset" />
        <feComposite in="SourceGraphic" in2="offset" result="composite2" />
      </filter>
      <filter
        id="filter3722"
        x="-.099927"
        y="-.11402"
        width="1.1999"
        height="1.228"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-color="rgb(190,32,32)"
          flood-opacity=".49804"
          result="flood"
        />
        <feComposite
          in="flood"
          in2="SourceGraphic"
          operator="in"
          result="composite1"
        />
        <feGaussianBlur in="composite1" result="blur" stdDeviation="10" />
        <feOffset dx="0" dy="0" result="offset" />
        <feComposite in="SourceGraphic" in2="offset" result="composite2" />
      </filter>
    </defs>
    <g transform="translate(-3.6059 -4.1307)">
      <path
        transform="matrix(.47892 -.009899 .009899 .47892 65.463 119.49)"
        d="m179.54 167.05-240.17-4.9679 124.39-205.51z"
        fill="#00d100"
        filter="url(#filter2662)"
      />
      <path
        transform="matrix(.47892 .009899 .009899 -.47892 196.41 177.51)"
        d="m179.54 167.05-240.17-4.9679 124.39-205.51z"
        fill="#f55"
        filter="url(#filter3722)"
      />
      <text
        x="182.16551"
        y="267.2587"
        fill="url(#linearGradient1233)"
        font-family="sans-serif"
        font-size="10.583px"
        stroke-width=".26458"
        style="mix-blend-mode: normal"
        xml:space="preserve"
      >
        <tspan x="182.16551" y="267.2587" fill="url(#linearGradient1233)" />
      </text>
      <circle
        cx="125.37"
        cy="83.234"
        r="36.144"
        fill="url(#linearGradient1233)"
      />
      <text
        x="96.098541"
        y="79.98188"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        xml:space="preserve"
      >
        <tspan
          x="96.098541"
          y="79.98188"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Currency
        </tspan>
        <tspan
          x="96.098541"
          y="93.776108"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Derivative
        </tspan>
      </text>
      <circle
        cx="63.407"
        cy="161.72"
        r="35.318"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="64.702744"
        y="151.57413"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="64.702744"
          y="151.57413"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          NFO
        </tspan>
        <tspan
          x="64.702744"
          y="165.36835"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Derivative
        </tspan>
        <tspan
          x="64.702744"
          y="179.16258"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Index
        </tspan>
      </text>
      <circle
        cx="238.14"
        cy="165.02"
        r="33.252"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="238.19043"
        y="161.7758"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="238.19043"
          y="161.7758"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Option
        </tspan>
        <tspan
          x="238.19043"
          y="175.57002"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Index HO
        </tspan>
      </text>
      <circle
        cx="69.603"
        cy="244.75"
        r="31.187"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="69.602921"
        y="241.49904"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="69.602921"
          y="241.49904"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          MCX Live
        </tspan>
        <tspan
          x="69.602921"
          y="255.29326"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Tech
        </tspan>
      </text>
      <circle
        cx="145.82"
        cy="263.95"
        r="29.328"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="145.70813"
        y="260.72299"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="145.70813"
          y="260.72299"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          MCX ATL
        </tspan>
        <tspan
          x="145.70813"
          y="274.51721"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          BO
        </tspan>
      </text>
      <circle
        cx="72.908"
        cy="31.6"
        r="22.926"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="72.907509"
        y="35.250118"
        fill="#ffffff"
        font-family="sans-serif"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="72.907509"
          y="35.250118"
          fill="#ffffff"
          font-family="'Cooper Black'"
          font-size="10.945px"
          stroke-width=".27362"
          text-align="center"
          text-anchor="middle"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          MC
        </tspan>
      </text>
      <circle
        cx="266.85"
        cy="32.633"
        r="28.502"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="266.77078"
        y="28.474529"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="266.77078"
          y="28.474529"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Index
        </tspan>
        <tspan
          x="266.77078"
          y="42.268753"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Option
        </tspan>
      </text>
      <circle
        cx="249.08"
        cy="94.594"
        r="26.85"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="249.04872"
        y="91.346748"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="249.04872"
          y="91.346748"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          MCX
        </tspan>
        <tspan
          x="249.04872"
          y="105.14098"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          - ATL
        </tspan>
      </text>
      <circle
        cx="185.26"
        cy="36.144"
        r="27.882"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="185.19142"
        y="32.926212"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="185.19142"
          y="32.926212"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Equity
        </tspan>
        <tspan
          x="185.19142"
          y="46.720436"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          Cash
        </tspan>
      </text>
      <circle
        cx="39.035"
        cy="92.735"
        r="27.469"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="40.296665"
        y="89.517319"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="40.296665"
          y="89.517319"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          EQ ATL
        </tspan>
        <tspan
          x="40.296665"
          y="103.31155"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          BO
        </tspan>
      </text>
      <circle
        cx="168.74"
        cy="141.06"
        r="26.23"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="168.66847"
        y="137.81754"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="168.66847"
          y="137.81754"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          MCX
        </tspan>
        <tspan
          x="168.66847"
          y="151.61176"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          IDL
        </tspan>
      </text>
      <circle
        cx="127.64"
        cy="195.8"
        r="25.611"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="127.69321"
        y="192.57919"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="127.69321"
          y="192.57919"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          EQ
        </tspan>
        <tspan
          x="127.69321"
          y="206.37341"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          F&amp;O
        </tspan>
      </text>
      <circle
        cx="252.18"
        cy="260.86"
        r="24.578"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="252.10934"
        y="257.57684"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="252.10934"
          y="257.57684"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          MCX
        </tspan>
        <tspan
          x="252.10934"
          y="271.37106"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          RT
        </tspan>
      </text>
      <circle
        cx="196"
        cy="215"
        r="26.643"
        fill="url(#linearGradient1233)"
        style="mix-blend-mode: normal"
      />
      <text
        x="195.93134"
        y="211.75775"
        fill="#ffffff"
        font-family="'Cooper Black'"
        font-size="10.945px"
        stroke-width=".27362"
        text-align="center"
        text-anchor="middle"
        xml:space="preserve"
      >
        <tspan
          x="195.93134"
          y="211.75775"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          MCX
        </tspan>
        <tspan
          x="195.93134"
          y="225.55197"
          text-align="center"
          style="
            font-variant-caps: normal;
            font-variant-east-asian: normal;
            font-variant-ligatures: normal;
            font-variant-numeric: normal;
          "
        >
          ATL
        </tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "NiceBubbles",
  props: ["width"],
};
</script>

<style scoped>
svg {
  overflow: visible;
}
circle {
  filter: drop-shadow(0 0 10px rgb(212, 143, 235));
}

circle,
text:not(.excluded) {
  animation: up-and-down infinite 1400ms ease;
}

@keyframes up-and-down {
  0% {
    transform: translateY(15px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(15px);
  }
}
</style>
