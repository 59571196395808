<template>
  <div class="background">
    <nav v-if="!$vuetify.breakpoint.xs">
      <v-row justify="space-between" class="px-8 pt-8">
        <router-link to="/landing" class="d-flex align-center nice-link">
          <img src="/logo.svg" alt="Page Logo" height="80px" />
          <div>
            <div class="philosopher ml-3 pink--text lighten-3" style="font-size: 2rem">
              Goodwill Wealth Management Pvt Ltd
            </div>
          </div>
        </router-link>
        <div class="text--white d-flex align-center justify-end">
          <ColorfulButton @click="contactDialog = true">Register Now</ColorfulButton>
        </div>
      </v-row>
    </nav>
    <nav v-else>
      <v-row class="py-4 px-2 z-100">
        <v-col>
          <router-link to="/landing" class="d-flex align-center nice-link justify-center">
            <img src="/logo.svg" alt="Page Logo" height="64px" />
          </router-link>
        </v-col>
        <v-col class="d-flex align-center justify-end flex-1">
          <ColorfulButton @click="register">Register Now</ColorfulButton>
        </v-col>
      </v-row>
      <div :class="{ expand: isOpen }" class="mobile-menu">
        <div class="some-spacer"></div>
        <span v-for="header in headers" :key="header"
          class="text-h5 d-block text-center text--white py-2 my-4 font-weight-bold">
          {{ header }}
        </span>
      </div>
    </nav>
    <div>
      <section>
        <v-row class="flex-column-reverse flex-md-row">
          <v-col cols="12" md="6" class="px-12 d-flex align-center flex-column justify-center">
            <h1 class="text-md-h1 text-h3 text-center font-weight-black text--white">
              RNT <br />Research and Trade
            </h1>
            <p class="text--light-gray mt-6 text-center text-h5">
              Professional day traders absolute choice
            </p>
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-center align-center flex-column">
            <NiceBubbles width="80%" />
            <h2 class="gradient-border text-h4 text-md-h3 pb-2 mt-2 text-center">
              Plan Based Trading
            </h2>
          </v-col>
        </v-row>
      </section>
      <section>
        <v-row>
          <v-col cols="12" class="d-flex justify-center align-center relative" md="6" data-aos="fade-right">
            <img src="/drawing.svg" alt="background drawing" class="laptop-background" />
            <img src="/43924.png" alt="laptop" :width="$vuetify.breakpoint.xs ? '90%' : '70%'" class="image-shine" />
          </v-col>
          <v-col cols="12" class="d-flex flex-column align-center" md="6">
            <h2 class="text-md-h2 text-h3 text--white font-weight-black text-center mb-4" data-aos="fade-left">
              Live Call For Day Trading
            </h2>
            <h3 class="text-md-h3 text-h5 font-weight-bold text-center mb-6 text--gold" data-aos="fade-left">
              Based on Advanced Trading Plans
            </h3>
            <p class="text--light-gray text-h6" data-aos="fade-left">
              Take advantage of our day trading market calls and updates for the
              MCX and NSE Equity, Futures and Options markets. You can find
              success in day trading with the assistance of our professional
              advice.
            </p>
            <ColorfulButton class="mt-6" @click="openURL" data-aos="fade-left">
              Click to Learn More
            </ColorfulButton>
          </v-col>
        </v-row>
      </section>
      <section>
        <v-row>
          <v-col cols="12" class="d-flex flex-column align-center" md="6">
            <h2 class="text-md-h2 text-h3 text--white font-weight-black text-center mb-4" data-aos="fade-right">
              Trade Our Live Calls with API
            </h2>
            <h4 class="text-center mb-6 text--gold text-md-h4 text-h6" data-aos="fade-right">
              Pick the Right Plan and Automate
            </h4>
            <p class="text--light-gray text-h6" data-aos="fade-right">
              Effectively transforming virtual profit into real money. Choose
              the best course of action and keep an eye on it with our GWC
              Research Application. Place an order using our API and unwind
            </p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center align-center relative" md="6">
            <div class="steps">
              <div class="step" v-for="(step, idx) in steps" :key="step" data-aos="fade-left"
                :data-aos-delay="idx * 200">
                <div class="text--white text-h5" v-html="step" />
              </div>
            </div>
          </v-col>
        </v-row>
      </section>
      <section>
        <v-sheet class="standard-gradient rounded-xl px-4 d-flex flex-column align-center py-8" elevation="4" dark
          data-aos="fade-up">
          <h2 class="text-md-h2 text-h3 text--white font-weight-black text-center mb-4">
            A Quick Sneak Peak
          </h2>
          <h4 class="text-center mb-12 text--gold text-md-h4 text-h6">
            Take a quick sneak peak at what our software has to offer
          </h4>
          <v-carousel class="my-aspect-ratio" hide-delimiter-background cycle delimiter-icon="mdi-flash">
            <v-carousel-item v-for="i in 3" :key="i">
              <v-img :src="`/gwc-demo${i}.png`"></v-img>
            </v-carousel-item>
          </v-carousel>
          <!--TODO: Add some action here-->
          <ColorfulButton @click="openURL" class="mt-6">Start Now</ColorfulButton>
        </v-sheet>
      </section>
      <section>
        <div>
          <h2 class="text-md-h2 text-h3 text--white font-weight-black text-center mb-4">
            Features
          </h2>
          <h4 class="text-center mb-12 text--gold text-md-h4 text-h6">
            The plenty features that this app offer to you
          </h4>
          <v-row class="px-sm-12 w-80 mx-auto">
            <v-col v-for="feature in features" :key="feature.text" cols="6" md="4">
              <div class="h-100 px-sm-6 py-4 text-center">
                <div class="icon-container">
                  <ShinyCloudIcon />
                  <img :src="feature.img" alt="icon" class="icon" />
                </div>
                <p class="text--white text-center">
                  {{ feature.text }}
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </section>
      <section>
        <v-row>
          <v-col cols="12" class="d-flex flex-column align-center" md="5">
            <h2 class="text-md-h2 text-h3 text--white font-weight-black text-center mb-4" data-aos="fade-right">
              Intraday Trading in Stock and Commodity Trading
            </h2>
            <p class="text--light-gray text-h6" data-aos="fade-right">
              Goodwill will offer...
            </p>
            <ColorfulButton class="mt-6" @click="openURL" data-aos="fade-right">Interested?
            </ColorfulButton>
          </v-col>
          <v-col cols="12" class="d-flex justify-center align-center relative" md="7">
            <v-row>
              <v-col cols="12" md="6" class="d-flex flex-row" v-for="description in descriptions"
                :key="description.title">
                <div class="icon-container col-4">
                  <ShinyCloudIcon />
                  <img :src="description.icon" alt="icon" class="icon" />
                </div>
                <div class="col-8 text--white">
                  <h4>{{ description.title }}</h4>
                  <p>{{ description.details }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
      <diamond-divider />
      <section id="register">
        <v-row class="flex-column-reverse flex-md-row">
          <v-col cols="12" md="6" class="px-md-12 text-center">
            <h2 class="text-md-h2 text-h3 text--white font-weight-black text-center mb-4" data-aos="fade-left">
              Open a Free Demat Account Now
            </h2>
            <ColorfulButton @click="contactDialog = true" class="mt-6">Start Now</ColorfulButton>
          </v-col>
          <v-col cols="12 " class="h-100 px-md-8 mb-12 mb-md-0" md="6">
            <h2 class="text-md-h2 text-h3 text--white font-weight-black text-center mb-4" data-aos="fade-left">
              Contact Us
            </h2>
            <p class="text--light-gray text-body-1 text-center" data-aos="fade-left">
              Whether you're looking to get your wealth generation journey
              started, or have any queries, feel free to contact us!
            </p>
            <v-list class="colorless mt-4" dark data-aos="fade-left">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-body-2 text-md-body-1">
                  Goodwill Wealth Management Pvt Ltd.<br />
                  No.17,1st Cross,<br />
                  4th Main, Ananth Nagar,<br />
                  Electronic City,<br />
                  Bengaluru, <br />
                  Karnataka 560100
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-cellphone</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  +91 - 44 - 4032 9999, +91 - 90037 90027
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <a href="mailto:customersupport@gwcindia.in">customersupport@gwcindia.in</a>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider class="my-2" dark />
            <h5 class="text--white font-weight-bold mb-4" data-aos="fade-left">
              Social Media
            </h5>
            <v-row class="pl-4">
              <v-btn link class="cool-rounded-button mr-4" v-for="(media, idx) in socialMedia" :key="media.icon"
                :href="media.link" target="_blank" icon x-large data-aos="fade-up" :data-aos-delay="idx * 100">
                <v-icon size="36">{{ media.icon }}</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </section>
      <AppFooter />
    </div>
    <ContactUsDialog v-model="contactDialog" />
  </div>
</template>

<script>
import NiceBubbles from "@/components/NiceBubbles";
import ContactUsDialog from "@/components/ContactUsDialog";
import ColorfulButton from "@/components/ColorfulButton";
import ShinyCloudIcon from "@/components/ShinyCloudIcon";
import DiamondDivider from "@/components/DiamondDivider";
import AppFooter from "@/components/AppFooter";
import axios from "axios";

export default {
  name: "LandingPage",
  components: {
    AppFooter,
    DiamondDivider,
    ShinyCloudIcon,
    ContactUsDialog,
    NiceBubbles,
    ColorfulButton,
  },
  data() {
    return {
      contactDialog: false,
      headers: ["header 1", "header 2", "header 3"],
      isOpen: false,
      features: [
        {
          text: "One-stop dashboard to monitor all trade performance",
          img: "/dashboard.png",
        },
        {
          text: "Highly rated research calls have historically produced returns of above 400 percent",
          img: "/money-growth.png",
        },
        {
          text: "Download and analyze periodic performance reports.",
          img: "/business-report.png",
        },
        { text: "Historically Arranged Live Calls Database", img: "/file.png" },
        {
          text: "Advance Plans-powered Live Day Trading Calls",
          img: "/knowledge.png",
        },
        {
          text: "Utilize Our API Bridge to Automate Live Calls.",
          img: "/automation.png",
        },
      ],
      descriptions: [
        {
          title: "Deep Research",
          icon: "/data-analysis.png",
          details:
            "Avoid trial and error by relying on our research team's advise from professional traders on which shares or commodities to purchase.",
        },
        {
          title: "Discipline",
          icon: "/mental-control.png",
          details:
            "Maintaining rigorous targets for your trading positions, adhering to trade entries with stop losses, and adhering to the plan are all examples of discipline in intraday trading. Our recommendations come with strict supervision.",
        },
        {
          title: "Timing the Market",
          icon: "/stopwatch.png",
          details:
            "The market may fluctuate up and down, but don't worry, our guidance will strike the iron rod at just the right time. Picking up the turns and executing the order takes snake eyes.",
        },
        {
          title: "Custom Guidance",
          icon: "/presentation.png",
          details:
            "When traders follow our specialised advise, which focuses on financial and trading goals while knowing the investor risk capacity, intraday stock market trading becomes less risky.",
        },
      ],
      socialMedia: [
        // TODO: fill in the links
        { link: "https://www.facebook.com/gwcindia", icon: "mdi-facebook" },
        { link: "https://twitter.com/Goodwill_gwc_", icon: "mdi-twitter" },
        {
          link: "https://www.youtube.com/goodwillwealthmanagementpvtltd",
          icon: "mdi-youtube",
        },
      ],
      steps: [
        "Pick your Plan",
        "Login & Bridge the API",
        "Sit & Relax </br> It is automated",
      ],
      name: "",
      email: "",
      phone: "",
      password: "",
      referral: "",
    };
  },
  methods: {
    openURL() {
      const url = "https://app.gwcindia.live/";
      window.open(url, "_blank");
    },
    register() {
      document
        .getElementById("register")
        .scrollIntoView({ block: "end", behavior: "smooth" });
    },
    registerAccount() {
      // TODO: fill in the url and add then
      axios
        .post("https://app.gwcindia.live/API2022/api.php", {
          cmd: "registercall",
          name: this.name,
          email: this.email,
          phone: this.phone,
          contact: this.contact,
        })
        .then();
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

section {
  padding: 3rem 2rem;
  min-height: 90vh;
  display: flex;
  justify-content: stretch;
  align-items: center;

  >div {
    flex: 1;
  }
}

.background {
  padding-top: 80px;
  background: linear-gradient(135deg, #0b051f, #19143c);
}

.gradient-border {
  background: -webkit-linear-gradient(-45deg, #ee8dfb 5%, #2828be 95%);
  letter-spacing: 5px !important;
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  color: #232d2d;
  text-shadow: hotpink 0 0 10px;
}

.standard-gradient {
  background: linear-gradient(45deg, #4e2066, #1f0f61);
}

.z-100 {
  z-index: 100;
  position: relative;
}

.icon-container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .icon {
    position: absolute;
    width: 60%;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.image-shine {
  filter: drop-shadow(0 0 10px hotpink);
}

.laptop-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
}

.my-aspect-ratio {
  height: 500px;
  width: 969.35px;
}

.mobile-menu {
  position: fixed;
  z-index: 99;
  height: 100vh;
  width: 100%;
  top: 0;
  background: linear-gradient(0, #351f92, #b5abff);
  max-height: 0;
  transition: max-height 400ms ease-in;
  overflow: hidden;

  .some-spacer {
    padding-top: 80px;
  }
}

.mobile-menu.expand {
  max-height: 100%;
}

.cool-rounded-button {
  @extend .standard-gradient;
  padding: 8px !important;

  i::before {
    color: darkgoldenrod;
  }
}

.steps {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 2px);
    top: 20px;
    height: calc(100% - 20px);
    border-left: lightgray dashed 4px;
  }

  .step {
    height: 160px;
    width: 300px;
    margin-bottom: 3rem;
    position: relative;
    background-image: url("../../public/drawing.svg");
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .my-aspect-ratio {
    height: 200px !important;
    width: 360px;
  }

  section {
    padding: 3rem 1rem;
  }

  .background {
    padding-top: 70px;
  }
}

@media screen and (max-width: 420px) {
  .my-aspect-ratio {
    width: 300px;
  }
}
</style>
