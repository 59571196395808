/* eslint-disable */
<template>
  <div class="pa-12">
    <div class="my-12"></div>
    <v-row class="live-call">
      <v-col cols="12" class="calls" sm="8" small>
        <h5 class="mb-2" data-aos="fade-up">MOST RECENT CALL</h5>
        <v-sheet class="most-recent" data-aos="fade-up">
          <h6 class="text-center caption">{{ mostRecent["date"] }}</h6>
          <div>
            <b>{{ mostRecent["name"] }} : </b>{{ mostRecent["value"] }}
          </div>
        </v-sheet>
        <h5 class="mt-6 mb-2" data-aos="fade-up" data-aos-delay="150">
          TODAY'S CALLS
        </h5>
        <ChatBox :calls="theData" />
      </v-col>
      <v-col cols="12" class="px-sm-4" sm="4">
        <div class="btn-col">
          <div
            v-for="(item, idx) in items"
            :key="item.name"
            @click="selected = idx"
            data-aos="fade-left"
            :data-aos-delay="idx * 150"
          >
            <v-hover v-slot="{ hover }">
              <v-sheet
                class="nice-btn gradient-background"
                :class="{
                  selected: selected === idx,
                  dimmer: selected !== idx,
                }"
                :elevation="hover ? 8 : 4"
              >
                <div class="btn-text">
                  <div>{{ item.name }}</div>
                </div>
              </v-sheet>
            </v-hover>
          </div>
        </div>
        <div data-aos="fade-left" :data-aos-delay="600" class="mt-8">
          <v-hover v-slot="{ hover }">
            <v-sheet
              class="text-center performance-analysis gradient-background"
              :elevation="hover ? 6 : 4"
              @click="goTo('strategies')"
            >
              <v-btn
                class="my-4 px-5 animate__animated animate__heartBeatSkipped animate__slowest animate__infinite"
                icon
                color="yellow"
                href="#"
              >
                <v-icon size="64">mdi-home</v-icon>
              </v-btn>
              <h4 class="glow perf-text">Back to Top</h4>
            </v-sheet>
          </v-hover>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChatBox from "@/components/ChatBox";
import axios from "axios";
import Vue from "vue";

export default {
  name: "LiveCall",
  components: { ChatBox },
  watch: {
    selected: function () {
      if (this.$vuetify.breakpoint.xs) {
        setTimeout(() => {
          [...document.querySelectorAll(".section.active [data-aos]")].forEach(
            (x) => x.classList.remove("aos-animate")
          );
          [...document.querySelectorAll(".section.active [data-aos]")].forEach(
            (x) => x.classList.add("aos-animate")
          );
        }, 150);
      }
    },
  },
  computed: {
    mostRecent() {
      if (this.selected === -1) {
        return {
          date: "loading...",
          value: "",
          name: "",
        };
      }
      return this.calls[this.items[this.selected].symbol]?.[0] ?? null;
    },
    theData() {
      if (this.selected === -1) {
        return [];
      }
      return this.calls[this.items[this.selected].symbol] ?? [];
    },
  },
  methods: {
    getData() {
      this.calls = {};
      axios.get("https://trade.gwcindia.live/report/earn.php").then((res) => {
        Object.keys(res.data).forEach((key) => {
          Vue.set(
            this.calls,
            key,
            res.data[key].map((sentence) => {
              let firstPart = sentence.slice(19).indexOf(":") + 19;
              return {
                date: sentence.slice(0, 19),
                value: sentence.slice(firstPart + 1),
                name: sentence.slice(19, firstPart).trim(),
              };
            })
          );
        });
      });
    },
  },
  data() {
    return {
      items: [
        { name: "Equity Cash", icon: "mdi-close", symbol: "EC" },
        { name: "NSE Futures", icon: "mdi-close", symbol: "NF" },
        { name: "NSE Options", icon: "mdi-close", symbol: "NO" },
        { name: "MCX Commodities", icon: "mdi-close", symbol: "MCX" },
      ],
      selected: 0,
      calls: {},
    };
  },
  mounted() {
    if (this.$vuetify.breakpoint.xs) {
      this.selected = -1;
    }
    this.getData();
  },
};
</script>

<style scoped lang="scss">
$glowsize: 2px;
.live-call {
  .btn-col {
    .nice-btn {
      display: flex;
      flex-direction: row;
      font-size: 24px;
      margin-bottom: 16px;
      transition: box-shadow 100ms linear;
      position: relative;
      height: 80px;
      &.selected {
        .btn-text {
          color: gold;
          transition: background-color 100ms linear;
        }
      }
      &:hover {
        cursor: pointer;
      }
      .btn-text {
        text-transform: uppercase;
        align-items: center;
        display: flex;
        color: white;
        justify-content: center;
        flex: 1;
        div {
          text-shadow: gray 1px 1px;
          font-weight: bold;
        }
      }
    }
  }
  .performance-analysis {
    padding: 8px 0;
    border-radius: 2px;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      box-shadow: gray 4px 4px 10px 4px;
    }

    .perf-text {
      font-size: 2.5rem;
      letter-spacing: 5px;
      color: #b4a650;
      text-align: center;
      &:nth-child(1) {
        color: #00c660;
      }
    }
  }

  .calls {
    h5 {
      text-align: center;
      font-size: 1.6rem;
      color: white;
      text-shadow: black 0 0 4px;
    }
  }
  .most-recent {
    padding: 4px 30px;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
    h6 {
      font-size: 16px;
    }
  }
}
.glow {
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 $glowsize #fff, 0 0 $glowsize * 2 #fff,
      0 0 $glowsize * 3 #ff8a37, 0 0 $glowsize * 4 #ff8a37,
      0 0 $glowsize * 5 #ff8a37, 0 0 $glowsize * 6 #ff8a37,
      0 0 $glowsize * 7 #ff8a37, gray 1px 1px;
  }

  100% {
    text-shadow: 0 0 $glowsize * 2 #fff, 0 0 $glowsize * 3 #ffae84,
      0 0 $glowsize * 4 #ffae84, 0 0 $glowsize * 5 #ffae84,
      0 0 $glowsize * 6 #ffae84, 0 0 $glowsize * 7 #ffae84,
      0 0 $glowsize * 8 #ffae84, gray 1px 1px;
  }
}
</style>
