<template>
  <div class="smaller-text">
    <div class="colored caption">{{ datetime }}</div>
    <span class="font-weight-bold">{{ username }}</span
    >: <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "SingleChatText",
  props: ["username", "text", "datetime"],
};
</script>

<style scoped lang="scss">
.smaller-text {
  margin: 8px 0;
  background-color: #f3e493;
  padding: 4px;
  border-radius: 4px;
  box-shadow: gray 1px 1px 3px 1px;
  height: 72px;
  transition: box-shadow ease-in 200ms;
  overflow: hidden;
  &:hover {
    box-shadow: gray 2px 2px 8px 3px;
  }
}
.colored {
  color: var(--primary);
  font-size: 12px;
}
</style>
