<template>
  <v-card class="gradient-background">
    <div class="text-center" style="font-size: 24px; font-weight: bold">
      Disclaimer
    </div>
    <div style="padding: 8px; font-size: 18px">
      Investments in the securities market are subject to market risk. Read all
      the related documents carefully before investing. The data and information
      herein provided is believed to be reliable, but we do not warrant for its
      accuracy or completeness. This material is not intended as an offer or
      solicitation for the purchase or sale of any financial instrument. <br/>
      Please Do your own analysis before investing and we are not responsible
      for any profit or loss based on the technical information provided.<br/>
      <b>Special note:</b> Short-term trading on the basis of technical is a
      high risk skill-oriented venture and may result in huge losses also.
      Traders doing so are doing at their own risk. We are not responsible for
      any damages.
    </div>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
