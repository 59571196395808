<template>
  <v-card class="gradient-background">
    <!--Add Image-->
    
    <v-table style="width: 100%">
      <thead>
        <tr>
          <th colspan="3">Margin Required for Plans</th>
        </tr>
        <tr>
          <th class="px-4">Plan</th>
          <th class="px-4">Total Calls Live</th>
          <th class="px-4">Margins Required</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in contents" :key="item.strat" class="my-2">
          <td class="px-4">{{ item.strat }}</td>
          <td class="text-center px-4">{{ item.total }}</td>
          <td
            class="text-right px-4"
            :style="
              [3, 5, 6, 13, 14, 15, 16, 17, 18].includes(index)
                ? 'background-color: #41B88390'
                : ''
            "
          >
            {{ item.required }}
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      contents: [
        /*{ strat: "Equtiy Cash", total: "5 (QTY '50')", required: 150000 },
        { strat: "EQ ATL BO", total: 4, required: 400000 },
        { strat: "EQ F_O", total: 3, required: 300000 },
        { strat: "STOCKOPTION", total: 3, required: 90000 },
        { strat: "NFODerivativeIndex", total: 1, required: 120000 },
        { strat: "INDEXOPTION", total: 2, required: 25000 },
        { strat: "OPTIONINDEXHO", total: 2, required: 25000 },
        { strat: "STOCKOPTIONHO", total: 3, required: 90000 },
        { strat: "MC", total: 1, required: 210000 },
        { strat: "MCXRT", total: 3, required: 1200000 },
        { strat: "MCX-IDL", total: 3, required: 1200000 },
        { strat: "MCX-ATL", total: 3, required: 1200000 },
        { strat: "MCXATLBO", total: 3, required: 1200000 },
        { strat: "MCXLIVETECH", total: 3, required: 1200000 },
        { strat: "MCX-LT-Crude-Mega", total: 1, required: 210000 },
        { strat: "MCX-LT-NGas-Mega", total: 1, required: 150000 },
        { strat: "MCX-LT-Bullion-Mini", total: 2, required: 130000 },
        { strat: "MCX-LT-Energy-Mini", total: 2, required: 50000 },
        { strat: "MCX-LT-Metals-Mini", total: 3, required: 90000 },
        { strat: "MCX-RT-Bullion-Mini", total: 2, required: 130000 },
        { strat: "MCX-RT-Energy-Mini", total: 2, required: 50000 },
        { strat: "MCX-RT-Metals-Mini", total: 3, required: 90000 },
        { strat: "MCX-RT-Copper-Mega", total: 1, required: 210000 },
        { strat: "MCX-RT-NGas-Mega", total: 1, required: 150000 },
        { strat: "Currency Derivative", total: 2, required: 25000 },
        { strat: "-", total: "-", required: "-" },*/
        { strat: "Equtiy Cash", total: "5 (QTY '50')", required: 150000 },
        { strat: "EQ ATL BO", total: 4, required: 400000 },
        { strat: "EQ F_O", total: 3, required: 300000 },
        { strat: "STOCKOPTION", total: 3, required: 90000 },
        { strat: "NFODerivativeIndex", total: 1, required: 120000 },
        { strat: "INDEXOPTION", total: 2, required: 25000 },
        { strat: "OPTIONINDEXHO", total: 2, required: 25000 },
        { strat: "STOCKOPTIONHO", total: 3, required: 90000 },
        { strat: "MC", total: 1, required: 130000 },
        { strat: "MCXRT", total: 3, required: 500000 },
        { strat: "MCX-IDL", total: 3, required: 500000 },
        { strat: "MCX-ATL", total: 3, required: 500000 },
        { strat: "MCXATLBO", total: 3, required: 500000 },
        { strat: "MCXLIVETECH", total: 3, required: 500000 },
        { strat: "MCX-LT-Crude-Mega", total: 1, required: 130000 },
        { strat: "MCX-LT-NGas-Mega", total: 1, required: 60000 },
        { strat: "MCX-LT-Bullion-Mini", total: 2, required: 120000 },
        { strat: "MCX-LT-Energy-Mini", total: 2, required: 50000 },
        { strat: "MCX-LT-Metals-Mini", total: 3, required: 90000 },
        { strat: "MCX-RT-Bullion-Mini", total: 2, required: 120000 },
        { strat: "MCX-RT-Energy-Mini", total: 2, required: 50000 },
        { strat: "MCX-RT-Metals-Mini", total: 3, required: 90000 },
        { strat: "MCX-RT-Copper-Mega", total: 1, required: 210000 },
        { strat: "MCX-RT-NGas-Mega", total: 1, required: 60000 },
        { strat: "Currency Derivative", total: 2, required: 25000 },
      ],
    };
  },
};
</script>

<style scoped>
thead {
  background-color: #fff;
}
tbody {
  background-color: #fffa;
}

th,
td {
  border-collapse: collapse;
  border: 1px solid black;
}
th {
  font-size: 22px;
  font-weight: bold;
}
td {
  font-size: 18px;
}
</style>
