<template>
  <header class="fix top-0 glass">
    <v-row>
      <v-col cols="3" class="d-flex pt-8 align-start pl-8">
        <div class="pl-8">
          <div>
            <div class="d-flex">
              <div
                class="d-inline-flex mr-2"
                style="
                  position: relative;
                  justify-content: center;
                  align-items: center;
                "
              >
                <slot name="top-left">
                  <router-link to="/" class="px-8 nice-link"
                    ><v-icon class="mr-1">mdi-chevron-left</v-icon>Back to
                    Home</router-link
                  >
                </slot>

                <div
                  style="
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateY(calc(100% + 20px)) translateX(-25%);
                    display: flex;
                  "
                >
                  <v-btn
                    small
                    color="#A59CD8"
                    href="tel:044-40329999"
                    class="mr-12"
                  >
                    <v-icon size="16" class="mr-1">mdi-phone</v-icon>
                    044-40329999
                  </v-btn>
                  <v-btn
                    color="#FFCC34"
                    rounded
                    class="d-inline-block animate__slowest mt-1 animate__jello_delay animate__animated animate__infinite"
                    @click="disclaimer = true"
                    small
                  >
                    Disclaimer
                  </v-btn>
                </div>
              </div>
              <div>
                <v-btn
                  color="#FFCC34"
                  rounded
                  class="animate__jello_delay animate__animated animate__infinite d-inline-block animate__slowest mt-1"
                  @click="marginRequired = true"
                  small
                >
                  Funds Required
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <v-row no-gutters>
          <v-col class="flex-grow-0">
            <img src="/logo.svg" alt="Logo" id="page-logo" class="mr-2" />
          </v-col>
          <v-col class="flex-grow-1">
            <h1 class="d-flex align-center justify-center">
              RNT (Research And Trade)
              <sup><span style="font-size: 15px">v1.0</span></sup>
            </h1>
            <h2 class="text-center">
              <slot name="top"></slot>
            </h2>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" class="d-flex align-start justify-end pt-8 pr-8">
        <slot name="top-right">
          <v-btn
            color="#FFCC34"
            rounded
            class="animate__jello_delay animate__animated animate__infinite d-inline-block animate__slowest"
            @click="dialog = true"
            v-if="!isLogged"
          >
            <h4>Login to Trade</h4>
          </v-btn>
          <v-btn
            color="#FFCC34"
            rounded
            class="animate__jello_delay animate__animated animate__infinite d-inline-block animate__slowest"
            @click="dialog = false"
            v-if="isLogged"
          >
            <h4>Logout</h4>
          </v-btn>
        </slot>
      </v-col>
    </v-row>
    <LoginDialog v-model="dialog" @loginDone="login" ref="ld" />
    <v-dialog v-model="marginRequired" width="616px">
      <v-template>
        <MarginRequired />
      </v-template>
    </v-dialog>
    <v-dialog v-model="disclaimer" width="616px">
      <v-template>
        <DisclaimerDialog />
      </v-template>
    </v-dialog>
  </header>
</template>

<script>
import LoginDialog from "@/components/LoginDialog";
import MarginRequired from "@/components/MarginRequired";
import DisclaimerDialog from "./DisclaimerDialog";
import router from "@/router";
export default {
  name: "AppHeader",
  components: { LoginDialog, MarginRequired, DisclaimerDialog },

  data() {
    return {
      dialog: false,
      marginRequired: false,
      disclaimer: false,
      loginData: "",
      lemail: "",
      isLogged: false,
    };
  },
  methods: {
    login(token) {
      this.$emit("loginDone", token);
      this.loginData = token;
      this.lemail = this.$refs.ld.email;
      this.isLogged = true;
      router.replace("/app");
    },
    logout() {
      localStorage.removeItem("gwc-login-token");
      this.isLogged = false;
      this.$emit("logoutDone");
      this.lemail = "";
    },
  },
  mounted() {},
};
</script>

<style scoped>
#page-logo {
  height: 120px;
  width: auto;
  padding-top: 12px;
}
.fix {
  position: fixed;
  z-index: 99;
  width: 100%;
}
.top-0 {
  top: 0;
}
</style>
