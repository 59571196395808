<template>
  <svg
    width="100%"
    viewBox="0 0 125.68 88.87"
    xmlns="http://www.w3.org/2000/svg"
    class="mb-4"
  >
    <defs>
      <linearGradient
        id="linearGradient3940"
        x1="27.91"
        x2="28.123"
        y1="57.283"
        y2="135.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#06307f" offset="0" />
        <stop stop-color="#2b0e5f" offset="1" />
      </linearGradient>
    </defs>
    <g transform="translate(35.548 -53.982)">
      <path
        d="m44.823 62.208c6.8213 8.8883 20.257 13.436 31.626 12.816 11.369-0.62012 17.983 13.849 10.129 23.564-7.8548 9.7152 3.514 25.218-37 36.174-40.514 10.955-68.213 12.402-75.448-7.2347-7.2347-19.637-16.33-38.447-1.8604-48.576 22.728-7.4647 55.652-39.633 72.554-16.743z"
        fill="url(#linearGradient3940)"
        stroke="#000"
        stroke-width=".26458px"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ShinyCloudIcon",
};
</script>

<style scoped>
svg {
  filter: drop-shadow(0 0 10px #4a1e66);
}
</style>
