<template>
  <div class="h-100" v-breathing-colors="gradientBackgroundColor">
    <v-navigation-drawer permanent class="standard-gradient pt-2" dark app>
      <router-link to="/app" class="d-flex text-decoration-none"
        ><v-icon color="#EDBE30">mdi-chevron-left</v-icon>
        <div style="color: #edbe30">Back to App</div></router-link
      >
      <v-list-item>
        <v-list-item-content>
          <img src="/logo.svg" alt="Logo" id="page-logo" width="100%" />
          <v-list-item-title class="text-h5 wrap mt-4 text-center">
            GWC Research Application
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="nav">
          <v-list-item v-for="({ icon, text }, i) in items" :key="i" link>
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <main class="pt-0 px-8">
      <h1 class="text-center pt-2">
        {{ items[nav].text }}
      </h1>
      <v-data-table
        :headers="headers[nav]"
        :items="data[nav]"
        :items-per-page="5"
        class="elevation-1"
      ></v-data-table>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
export default {
  name: "AppDashboard",
  mounted() {
    if (!localStorage.getItem("gwc-login-token")) {
      this.$router.replace("/");
    }
    axios
      .post("https://app.gwcindia.live/API2022/api.php", {
        cmd: "applog",
        token: localStorage.getItem("gwc-login-token"),
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "success") {
          this.data.push(res.data.balance);
          this.data.push(res.data.positions);
          this.data.push(res.data.orderbook);
          this.data.push(res.data.tradebook);
          this.data.push(res.data.strategy);
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    // TODO: fill in these values on mounted
    return {
      items: [
        { icon: "mdi-wallet", text: "Balance" },
        { icon: "mdi-podium", text: "Position" },
        { icon: "mdi-book", text: "Order Book" },
        { icon: "mdi-swap-vertical", text: "Trade Book" },
        { icon: "mdi-strategy", text: "Plan Order" },
        { icon: "mdi-logout", text: "Session Logout" },
      ],
      nav: 0,
      headers: [
        [
          { text: "Segment", value: "segment" },
          { text: "Credits", value: "credits" },
          { text: "Debits", value: "debits" },
          { text: "Opening Balance", value: "openingbalance" },
          { text: "Utilized Amount", value: "utilizedamount" },
          { text: "UnbookedPNL", value: "unbookedpnl" },
          { text: "PayIn Amount", value: "payinamt" },
          { text: "PayOut Amount", value: "payoutamt" },
        ],
        [
          { text: "Symbol", value: "product" },
          { text: "Buy Quantity", value: "bqty" },
          { text: "Sell Quantity", value: "sqty" },
          {
            text: "Realized Profit Loss",
            value: "realisedprofitloss",
          },
          { text: "Unrealized Profit Loss", value: "unrealisedprofitloss" },
          { text: "Net Quantity", value: "netqty" },
          { text: "LTP", value: "ltp" },
        ],
        [
          { text: "Trading Symbol", value: "tsym" },
          { text: "Product Code", value: "product" },
          { text: "Price Type", value: "pricetype" },
          { text: "Price", value: "price" },
          { text: "Quantity", value: "qty" },
          { text: "Trigger Price", value: "trgprc" },
          { text: "Noren OrderID", value: "exchordid" },
          { text: "TimeStamp", value: "orderentrytime" },
          { text: "Exchange Confirm Time", value: "exchconfrmtime" },
          { text: "Status", value: "status" },
          { text: "Rej Reason", value: "rejreason" },
        ],
        [
          { text: "Trading Symbol", value: "tsym" },
          { text: "Product Code", value: "product" },
          { text: "Price Type", value: "pricetype" },
          { text: "Price", value: "price" },
          { text: "Quantity", value: "qty" },
          { text: "exchordid", value: "exchordid" },
        ],
        [
          { text: "Date/Plan", value: "Date" },
          { text: "Time", value: "Time" },
          { text: "OrderID", value: "OrderID" },
          { text: "Symbol", value: "Symbol" },
          { text: "Quantity", value: "Qty" },
          { text: "Price", value: "Price" },
          { text: "Target", value: "Target" },
          { text: "StopLoss", value: "StopLoss" },
          { text: "Order Type", value: "oType" },
          { text: "Plan", value: "Strategy" },
          { text: "Option Type", value: "PECE" },
          { text: "Option Price", value: "OPrice" },
        ],
      ],
      data: [],
    };
  },
};
</script>

<style scoped></style>
