<template>
  <button
    class="d-inline-block animate__slowest overflow-hidden rounded-pill shiny"
    :class="
      animated
        ? 'animate__heartBeatSkipped animate__animated animate__infinite'
        : ''
    "
    @click="onClick"
  >
    <span
      v-ripple
      class="infinite-gradient px-6 py-2 text--white d-block font-weight-bold text-uppercase"
    >
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: "ColorfulButton",
  props: {
    animated: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.shiny {
  box-shadow: 0 0 8px 2px hotpink;
}
</style>
