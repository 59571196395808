<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <!--This is for Login-->
      <v-card v-show="!isRegister" class="simple-gradient-background">
        <v-card-title class="primary justify-center">
          <span class="text-h5 white--text">Login </span>
        </v-card-title>
        <v-card-text class="py-6">
          <v-form ref="loginForm">
            <v-text-field
              v-model="cid"
              label="Client ID"
              outlined
              clearable
              prepend-icon="mdi-book"
              type="text"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              outlined
              clearable
              prepend-icon="mdi-lock"
              class="my-4"
              type="password"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>

          <p class="text-center">
            Not a user yet? <a @click="toggleForm">Register now</a>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple darken-1" text @click="resetPass">
            Reset Password
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit"> Login </v-btn>
        </v-card-actions>
      </v-card>
      <!--This is for registration-->
      <v-card v-show="isRegister" class="simple-gradient-background">
        <v-card-title class="primary justify-center">
          <span class="text-h5 white--text">Register</span>
        </v-card-title>
        <v-card-text class="py-6">
          <v-form ref="registerForm">
            <v-text-field
              v-model="name"
              label="Name"
              outlined
              clearable
              prepend-icon="mdi-account"
              class="mb-4"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="cid"
              label="Client ID"
              outlined
              clearable
              prepend-icon="mdi-book"
              class="mb-4"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="phone"
              label="Phone Number"
              outlined
              clearable
              prepend-icon="mdi-phone"
              class="mb-4"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="Email"
              outlined
              clearable
              prepend-icon="mdi-email"
              type="email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              outlined
              clearable
              prepend-icon="mdi-lock"
              class="my-4"
              type="password"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="passwordConfirmation"
              label="Password Confirmation"
              outlined
              clearable
              prepend-icon="mdi-lock"
              class="my-4"
              type="password"
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-form>
          <p class="text-center">
            Already a user? <a @click="toggleForm">Login</a>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="register"> Register </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      centered
      bottom
      absolute
    >
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginDialog",
  props: ["value"],
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        return this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      name: "",
      password: "",
      passwordConfirmation: "",
      email: "",
      phone: "",
      isRegister: false,
      error: "",
      snackbarColor: "red accent-2",
      snackbar: false,
      LoggedIn: false,
      Token: "",
      cid: "",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        sameAsPassword: (value) =>
          value !== this.password || "Must match password",
      },
    };
  },
  methods: {
    reset() {
      this.name = "";
      this.password = "";
      this.email = "";
      this.phone = "";
      this.passwordConfirmation = "";
      this.Token = "";
      this.LoggedIn = false;
      this.cid = "";
    },
    toggleForm() {
      this.reset();
      this.isRegister = !this.isRegister;
    },
    /*sendOtp() {
      //TODO fill in the urls here
      axios
        .post("url_here", {
          username: this.username,
          password: this.password,
          email: this.email,
          phone: this.phone,
        })
        .then(() => {
          this.otpSent = true;
        })
        .catch(() => {
          this.error = "Wrong email or password, please try again";
          this.snackbar = true;
        });
    },*/
    submit() {
      if (this.$refs.loginForm.validate()) {
        axios
          .post("https://app.gwcindia.live/API2022/api.php", {
            cmd: "login",
            cid: this.cid,
            password: this.password,
          })
          .then((res) => {
            console.log(res.data);
            if (res.data == ".") {
              this.error = "Wrong email or password, please try again";
              this.snackbarColor = "red accent-2";
              this.snackbar = true;
            } else {
              if (res.data.status == "OK") {
                localStorage.setItem("gwc-login-token", res.data.msg);
                this.error = "Login Successful";
                this.snackbarColor = "green accent-2";
                this.LoggedIn = true;
                this.Token = res.data.msg;
                this.dialog = false;
                this.success = true;
                this.$emit("loginDone", res.data.msg);
              } else {
                this.error = res.data.err; //"Wrong email or password, please try again";
                this.snackbarColor = "red accent-2";
                this.snackbar = true;
              }
            }
          })
          .catch(() => {
            this.error = "Wrong Password!";
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          });
      }
    },
    resetPass() {
      if (this.$refs.loginForm.cid == "") {
        this.error = "Please enter your Client id";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
        //return;
      } else {
        axios
          .post("https://app.gwcindia.live/API2022/api.php", {
            cmd: "resetpass",
            rid: this.cid,
          })
          .then((res) => {
            console.log("Returned");
            console.log(res);
            if (res.data == ".") {
              this.error = "Invalid Information, please try again";
              this.snackbarColor = "red accent-2";
              this.snackbar = true;
            } else {
              if (res.data.status == "OK") {
                //this.dialog = false;
                this.error = "Password Reset Mail Sent successfully";
                this.snackbarColor = "green accent-2";
                this.snackbar = true;
              } else {
                this.error = res.data.err; //"Wrong email or password, please try again";
                this.snackbarColor = "red accent-2";
                this.snackbar = true;
              }
            }
            console.log(res);
            this.success = true;
          })
          .catch(() => {
            this.error = "Something went wrong, please try again";
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          });
      }
    },
    register() {
      axios
        .post("https://app.gwcindia.live/API2022/api.php", {
          cmd: "addUser",
          cid: this.cid,
          name: this.name,
          password: this.password,
          email: this.email,
          phone: this.phone,
        })
        .then((res) => {
          if (res.data == ".") {
            this.error = "Invalid Information, please try again";
            this.snackbarColor = "red accent-2";
            this.snackbar = true;
          } else {
            if (res.data.status == "OK") {
              //this.dialog = false;
              this.toggleForm();
            } else {
              this.error = res.data.err; //"Wrong email or password, please try again";
              this.snackbarColor = "red accent-2";
              this.snackbar = true;
            }
          }
          console.log(res);
          this.success = true;
          this.toggleForm();
        })
        .catch(() => {
          this.error = "Something went wrong, please try again";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.simple-gradient-background {
  background: linear-gradient(135deg, #ccf4ff, #a1cfff, #83abff);
}
.goldcolor {
  color: #f9c80e;
}
</style>
