import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "./assets/global.scss";
import "@mdi/font/css/materialdesignicons.css";
import "animate.css";
import BreathingColors from "vue-breathing-colors";
//import ELink from "@/components/ELink.vue";

Vue.use(BreathingColors);
Vue.config.productionTip = false;

Vue.mixin({
  data() {
    return {
      gradientBackgroundColor: {
        colors: ["#d6d1ff", "#9196db", "#7974a3", "#9aa4ff", "#919ce3"],
        interval: 1000,
        transition: {
          duration: 1000,
        },
      },
    };
  },
  methods: {
    goTo(id) {
      let element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    parseDate(dateString) {
      let parts = dateString.split("-");
      return new Date(parts[2], parts[1] - 1, parts[0]);
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    toDateString(date) {
      let parsed = new Date(date);
      const day = parsed.toLocaleString("default", { day: "2-digit" });
      const month = parsed.toLocaleString("default", { month: "long" });
      const year = parsed.toLocaleString("default", { year: "numeric" });
      return day + " " + month + " " + year;
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
