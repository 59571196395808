import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ReportVIew from "@/views/ReportVIew";
import LandingPage from "@/views/LandingPage";
import MainAppView from "@/views/MainAppView";
import AppDashboard from "@/views/AppDashboard";
import ContactUs from "@/views/ContactUs";

Vue.use(VueRouter);

// TODO: refer to this link and implement route guard, check on localstorage's gwc-login-token for validation
// It is skipped now so that the pages can be visited until the login mechanism is completed
// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
const routes = [
  {
    path: "/External/gwc.php",
    name: "gwcapi",
    beforeEnter() {
      location.href = "https://trade.gwcindia.live/External/gwc.php";
      //window.open("https://trade.gwcindia.live/External/gwc.php", "_blank");
    },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/report",
    name: "report",
    component: ReportVIew,
  },
  {
    path: "/landing",
    name: "landing",
    component: LandingPage,
  },
  {
    path: "/app",
    name: "app",
    component: MainAppView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: AppDashboard,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactUs,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
